@import url(https://fonts.googleapis.com/css?family=Roboto:300);

// @import '../node_modules/@fortawesome/fontawesome-free/scss/');

// @import '~@fortawesome/fontawesome-free/scss/fontawesome';
// @import '~@fortawesome/fontawesome-free/scss/solid';
// @import '~@fortawesome/fontawesome-free/scss/brands';
// @import '~@fortawesome/fontawesome-free/scss/regular';

// @import 'scss/fontawesome.scss';

@import '~bootstrap/scss/bootstrap-grid';
@import 'components/jplayer-theme.scss';

$color-background: #181818;
$color-item-background: #282828;
$color-type: #fefefe;
$thumb-side: 240px;
$item-height: 400px;

@mixin center-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.artist {
    width: $thumb-side;
    height: $item-height;
    margin-right: 20px;
    background: $color-item-background;


    &:last-child {
        margin: 0;
    }

    &__thumbnail {
        position: relative;
        width: $thumb-side;
        height: $thumb-side;
        overflow: hidden;
    }

    &__blur {
        position: absolute;
        top: 0;
        left: 0;
        filter: blur(40px);
        z-index: 1;
    }

    &__image {
        width: calc($thumb-side / 2);
        height: calc($thumb-side / 2);
        border-radius: 50%;
        z-index: 3;
        @include center-center;
    }

    &__ring {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        background: linear-gradient(90deg, rgba(255, 255, 255, .2) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 80%, rgba(255, 255, 255, .2) 100%);
        z-index: 2;
        box-shadow: 4px 0 2px -4px rgba(255, 255, 255, .9), -4px 0 2px -4px rgba(255, 255, 255, .9);
        @include center-center;

        &--outer {
            width: 200px;
            height: 200px;
        }
    }

    &__label {
        display: flex;
        height: ($item-height - $thumb-side);
        flex-direction: column;
        justify-content: space-between;
        padding: 15px 10px;
    }
}

.capital {
    color: darken($color-type, 50);
    text-transform: uppercase;

    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    height: 80px;

}

*,
*:after,
*:before {
    box-sizing: border-box;
}

html {
    height: 100%;
    margin: 0;
}

body {
    @extend html;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    background: $color-background;
    color: $color-type;
    font-family: Roboto;
    font-size: 14px;
}

.availableStations {
    // max-width: 1100px;
    // position: absolute;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .artist {
        cursor: pointer;
        float: left;
        margin: 10px;
    }
}

#jp_container_1 {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: calc(100vw - 15px);
    overflow: hidden;
    z-index: 100;

    @media (max-width: 767.98px) {
        width: calc(100vw);
    }
}
.site-logo {
    height: 100px;
    width: 150px;
}
.site-logo.tr {
    width: 200px;
    height: 140px;
}
.copyright {
    margin-top: 30px;
    text-align: center;
    font-size: 11px;
    color: #838383;
}
.site-header {
    padding: 20px;
    .site-title {

        // max-width: 1100px;
        text-align: center;

        h1 {
            font-weight: 600;
            font-family: Arial;
        }
    }
}